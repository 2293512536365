<template>
  <div class="repair">
    <div class="opreation">
      <el-button size="small" @click="nosolve">未解决导出excel</el-button>
      <el-button size="small" @click="huifu">回复</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      highlight-current-row
      @current-change="handleChange"
      :row-style="{ height: '50px' }"
      style="
         {
          width: 100%;
        }
      "
      max-height="800"
    >
      <el-table-column prop="no" label="编号" width="50"> </el-table-column>
      <el-table-column prop="roomName" label="房屋号" width="180">
      </el-table-column>
      <el-table-column prop="tel" label="电话" width="200"> </el-table-column>
      <el-table-column label="类型" width="50" prop="type"></el-table-column>
      <el-table-column prop="content" label="内容" width="auto">
      </el-table-column>
      <el-table-column label="状态" prop="status" width="80"></el-table-column>
      <el-table-column
        label="解决内容"
        prop="solve_content"
        width="auto"
      ></el-table-column>
      <el-table-column
        label="解决时间"
        prop="solve_time"
        width="100"
      ></el-table-column>
      <el-table-column prop="imgs" label="查看照片" width="80">
        <template #default="scope">
          <el-button size="mini" @click="handImg(scope)" v-show="scope.row.imgs"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="发布时间" width="200">
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        @current-change="handlePagination"
        v-model:currentPage="currentPage1"
        :page-size="15"
        layout="total, prev, pager, next"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
  <el-dialog :title="title" v-model="huifuModel">
    <el-form :model="Form" ref="formRef" :rules="rulesForm">
      <el-form-item>
        <el-input v-model="Form.huifuTitle"></el-input>
      </el-form-item>
      <el-form-item prop="content">
        <el-input type="textarea" :rows="5" v-model="Form.content"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="huifuModel = false">取 消</el-button>
        <el-button @click="submitHuifu" type="primary">回复</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 图片弹框 -->
  <el-dialog v-model="imgModel" :title="'点击查看大图'">
    <span class="demo-image__preview" v-for="(item,index) in srcList" :key="index" style="margin-left:5px">
      <el-image
        style="width: 100px; height: 100px"
        :src="item"
        :preview-src-list="srcList"
      >
      </el-image>
    </span>
  </el-dialog>
</template>

<script>
// @ is an alias to /src
import { reactive, ref, getCurrentInstance } from "vue";
import { complaint, sendUnread } from "../http/complaint";
import { ElMessage } from "element-plus";
import fileDownload from "js-file-download";
import { postHuifu } from "../http/repair";
import { useStore } from "vuex";
import axios from "axios";
export default {
  name: "Repair",
  setup() {
    const store = useStore();
    const { ctx } = getCurrentInstance();
    const currentId = ref(0);
    const imgModel = ref(false);
    const imgUrl = ref("");
    const srcList = reactive([]);
    const tableData = reactive([
      {
        no: "",
        roomName: "",
        content: "",
        imgs: "",
        create_time: "",
        tel: "",
        doing: "",
        type: "维修",
        status: "",
        solve_content: "",
        solve_time: "",
        id: "",
        is_read: "",
      },
    ]);
    const formRef = ref(null);
    const currentIs_read = ref("");
    const title = ref("回复内容");
    const currentPage1 = ref(1);
    const Form = reactive({
      huifuTitle: "报修",
      content: "",
    });
    const totalCount = ref(0);
    let rulesForm = reactive({
      content: [
        { required: "true", message: "内容不能为空", trigger: ["blur"] },
      ],
    });
    const huifuModel = ref(false);
    function handleChange(val) {
      currentId.value = val.id;
      currentIs_read.value = val.is_read;
      if (!currentIs_read.value) {
        sendUnread(currentId.value).then((res) => {
          complaint(totalCount.value, 1, 1).then((res) => {
            let data = res.data;
            let state = data.state;
            if (state == 0) {
              let num = data.data.rows.filter((item) => {
                return item.is_read == 0;
              }).length;
              store.commit("repair", num);
            }
          });
        });
      } else {
      }
    }

    //翻页请求
    function handlePagination(val) {
      getComplaint(val);
    }

    //初始化获取页面
    function getComplaint(page) {
      complaint(15, page, 1)
        .then((res) => {
          let data = res.data;
          totalCount.value = data.data.total;
          let state = data.state;
          if (state == 0) {
            tableData.length = 0;
            let resultData = data.data.rows;
            for (let i = 1; i <= resultData.length; i++) {
              let refData = {
                no: resultData[i - 1].no,
                roomName: resultData[i - 1].roomName,
                content: resultData[i - 1].content,
                imgs: resultData[i - 1].imgs,
                create_time: resultData[i - 1].create_time,
                tel: resultData[i - 1].tel,
                doing: resultData[i - 1].doing,
                type: "维修",
                status: resultData[i - 1].status ? "已解决" : "未解决",
                solve_content: resultData[i - 1].solve_content,
                solve_time: resultData[i - 1].solve_time,
                id: resultData[i - 1].id,
                is_read: resultData[i - 1].is_read,
              };
              tableData.push(refData);
            }
          } else if (state == 1) {
            ElMessage.error("请求失败");
          }
        })
        .catch((err) => {
          ElMessage.error(err);
        });
    }

    //导出未解决
    function nosolve() {
      axios
        .get(
          "https://rhkpwy.com/Advice/GetAdviceGson",
          {
            params: {
              adviceType: 1,
            },
          },
          { responseType: "arraybuffer" }
        )
        .then((res) => {
          fileDownload(res.data, "报修未解决.xlsx");
          ElMessage.success("导出成功");
        })
        .catch((err) => {
          ElMessage.error("导出失败");
        });
    }

    //回复
    function huifu() {
      if (!currentId.value) {
        ElMessage.warning("请先选择一项");
      } else {
        huifuModel.value = true;
      }
    }

    // 回复内容
    function submitHuifu() {
      formRef.value.validate((valid) => {
        if (valid) {
          postHuifu(currentId.value, Form.content).then((res) => {
            let data = res.data;
            let state = data.state;
            if (state == "1") {
              ElMessage.error(data.message);
            } else if (state == "0") {
              huifuModel.value = false;
              ElMessage.success("回复成功");
              getComplaint(1);
            }
          });
        }
      });
    }

    getComplaint(1);

    //查看图片 按钮
    function handImg(scope) {
      let imgs = scope.row.imgs;
      let http = "https://rhkpwy.com/";
      imgModel.value = true;
      srcList.length = 0;
      if (imgs.indexOf(",") != -1) {
        let arr = imgs.split(",");
        for (let i = 0; i < arr.length; i++) {
          srcList.push(http + arr[i]);
        }
      } else {
        srcList.length = 0;
        srcList.push(http + imgs);
      }
      // if (imgs.indexOf(",") != -1) {
      //   let arr = imgs.split(",");
      //   console.log(arr);
      //   imgUrl.value = http + arr[0];
      //   for (let i = 0; i < arr.length; i++) {
      //     srcList.push(http + arr[i]);
      //   }
      // } else {
      //   imgUrl.value = "";
      //   imgUrl.value = http + imgs;
      //   srcList.length = 0;
      //   srcList.push(http + imgs);
      // }
    }
    return {
      imgModel,
      srcList,
      imgUrl,
      handImg,
      rulesForm,
      Form,
      submitHuifu,
      title,
      huifuModel,
      nosolve,
      huifu,
      getComplaint,
      tableData,
      currentPage1,
      handleChange,
      handlePagination,
      totalCount,
      currentId,
      formRef,
    };
  },
};
</script>
<style scoped>
.complaint-sendContent {
  width: 500px;
}

.repair {
  width: 100%;
  height: 100%;
  position: relative;
}
.el-table /deep/ td {
  color: black;
}
.block {
  position: absolute;
  bottom: 0;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #ffe48d;
}
</style>
